import { images } from '../constants/images';
import {
  getUrlForPackageId,
  PackageCard,
  PackageId,
  usePackageCards,
} from '../components/package-card';
import { onOpenInquiry } from '../state/inquiry-modal';
import { useState, useCallback, useMemo } from 'react';
import { Modal } from '../components/modal';
import Sprite from '../sprites/sprite';
import { Accordion } from '../components/accordion';
import { useDisableBodyScroll } from '../hooks/use-disable-body-scroll';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { copyTextToClipboard } from '../hooks/copy-to-clipboard';

const usePackages = (t: TFunction<['home', 'common'], undefined>) => {
  const kumbh = useMemo(
    () => [
      {
        title: t('home:packages.kumbh.day-1.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.day-1.itinerary">
            <ul>
              <li className="list-disc ml-4">Arrive in Prayagraj</li>
              <li className="list-disc ml-4">
                Meet and greet at Prayagraj airport or station, check-in to Kumbh Sukrit
                camp.
              </li>
              <li className="list-disc ml-4">
                Participate in the festivities of Kumbh Mela - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.day-2.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.day-2.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Take a dip in the holy waters of the Ganges - Guided
              </li>
              <li className="list-disc ml-4">Guided tour of Kumbh Mela in a group</li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.day-3.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.day-3.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Perform the sacred prayers; auspicious fire ceremony &lsquo;Yajna&rsquo;-
                Guided
              </li>
              <li className="list-disc ml-4">
                Participate in yoga & meditation, activities at the Kumbh camp - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.day-4.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.day-4.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Guided tour of Maharshi Sadafal Deo Ashram
              </li>
              <li className="list-disc ml-4">
                Feed the holy cows in the Gau-shala - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.day-5.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.day-5.itinerary">
            <ul>
              <li className="list-disc ml-4">Boat ride in the Ganges - Guided</li>
              <li className="list-disc ml-4">
                Meeting the Saint, experiences of the real essence of Kumbh
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.day-6.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.day-6.itinerary">
            <ul>
              <li className="list-disc ml-4">Check-out after morning breakfast</li>
              <li className="list-disc ml-4">
                Onboard flight to next destination. Trip ends here
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.inclusions.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.inclusions.itinerary">
            <ol>
              <li className="list-decimal ml-4">
                Stay at Kumbh Sukrit Camp in Kumbh Mela, Prayagraj on twin share
              </li>
              <li className="list-decimal ml-4">
                All meals (breakfast, lunch, dinner), drinking water is included as per
                the published itinerary{' '}
              </li>
              <li className="list-decimal ml-4">
                Local knowledgeable guide in English, Hindi speaking during all events
              </li>
              <li className="list-decimal ml-4">
                Private vehicle (based on size of group) for sightseeing and travel as per
                itinerary
              </li>
              <li className="list-decimal ml-4">
                Entrance fees, and all other costs as per itinerary
              </li>
              <li className="list-decimal ml-4">
                First aid, Doctor on call will be available at Kumbh Mela camp
              </li>
            </ol>
          </Trans>
        ),
      },
      {
        title: t('home:packages.kumbh.exclusions.title'),
        content: (
          <Trans i18nKey="home:packages.kumbh.exclusions.itinerary">
            <ol>
              <li className="list-decimal ml-4">International Flights</li>
              <li className="list-decimal ml-4">Visa and/or Passport services</li>
              <li className="list-decimal ml-4">
                Cost of domestic flights will be charged on actuals at time of booking
              </li>
              <li className="list-decimal ml-4">
                Local Tours, Private Vehicle bookings that are not included in the
                itinerary
              </li>
              <li className="list-decimal ml-4">
                Individual requests for performing specific rituals
              </li>
              <li className="list-decimal ml-4">
                Any last-minute train/flight cancellations, occurrences of this nature
              </li>
              <li className="list-decimal ml-4">
                Any personal loss of money, equipment etc of any kind
              </li>
              <li className="list-decimal ml-4">
                Any service, anything not specified in the booking itinerary is not
                included. Sukrit wellness tours and Kumbh Sukrit Camp is not responsible
                for any services not agreed upon at the time of booking
              </li>
            </ol>
          </Trans>
        ),
      },
    ],

    [t],
  );

  const varanasi = useMemo(
    () => [
      {
        title: t('home:packages.varanasi.day-1.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-1.itinerary">
            <ul>
              <li className="list-disc ml-4">Arrive in Delhi/Mumbai/Calcutta</li>
              <li className="list-disc ml-4">
                Transfer and take next flight to Varanasi
              </li>
              <li className="list-disc ml-4">
                Meet and greet at Varanasi airport. Check-in to hotel in Varanasi
              </li>
              <li className="list-disc ml-4">
                In the evening Boat ride in the Ganges seeing the iconic riverfront - a
                UNESCO world heritage site - Guided
              </li>
              <li className="list-disc ml-4">
                Participate in the prayers offered on the banks of Ganges - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-2.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-2.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Guided tour of ancient Budha Stupa - a UNESCO world heritage
              </li>
              <li className="list-disc ml-4">
                Guided tour of Swarved Mahāmandir Dham - World&rsquo;s largest meditation
                center
              </li>
              <li className="list-disc ml-4">
                Guided visit to local market - local shopping, souvenir
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-3.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-3.itinerary">
            <ul>
              <li className="list-disc ml-4">
                After breakfast check-out and travel to Kumbh Mela, Prayagraj
              </li>
              <li className="list-disc ml-4">
                Participate in the festivities of Kumbh Mela - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-4.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-4.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Take a dip in the holy waters of the Ganges - Guided
              </li>
              <li className="list-disc ml-4">Guided tour of Kumbh Mela in a group</li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-5.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-5.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Perform the sacred prayers; auspicious fire ceremony &lsquo;Yajna&rsquo;-
                Guided
              </li>
              <li className="list-disc ml-4">
                Participate in yoga & meditation, activities at the Kumbh camp - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-6.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-6.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Guided tour of Maharshi Sadafal Deo Ashram
              </li>
              <li className="list-disc ml-4">
                Feed the holy cows in the Gau-shala - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-7.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-7.itinerary">
            <ul>
              <li className="list-disc ml-4">Boat ride in the Ganges - Guided</li>
              <li className="list-disc ml-4">
                Meeting the Saint, experiences of the real essence of Kumbh
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.day-8.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.day-8.itinerary">
            <ul>
              <li className="list-disc ml-4">Check-out after morning breakfast</li>
              <li className="list-disc ml-4">
                Onboard flight to next destination. Trip ends here
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.inclusions.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.inclusions.content">
            <ol>
              <li className="list-decimal ml-4">
                Accommodation in Hotel in Varanasi on twin share{' '}
              </li>
              <li className="list-decimal ml-4">
                Stay at Kumbh Sukrit Camp in Kumbh Mela, Prayagraj on twin share
              </li>
              <li className="list-decimal ml-4">
                All meals (breakfast, lunch, dinner), drinking water is included as per
                the published itinerary{' '}
              </li>
              <li className="list-decimal ml-4">
                Local knowledgeable guide in English, Hindi speaking during all events
              </li>
              <li className="list-decimal ml-4">
                Private vehicle (based on size of group) for sightseeing and travel as per
                itinerary
              </li>
              <li className="list-decimal ml-4">
                Entrance fees, and all other costs as per itinerary
              </li>
              <li className="list-decimal ml-4">
                First aid, Doctor on call will be available at Kumbh Mela camp
              </li>
            </ol>
          </Trans>
        ),
      },
      {
        title: t('home:packages.varanasi.exclusions.title'),
        content: (
          <Trans i18nKey="home:packages.varanasi.exclusions.content">
            <ol>
              <li className="list-decimal ml-4">International Flights</li>
              <li className="list-decimal ml-4">Visa and/or Passport services</li>
              <li className="list-decimal ml-4">
                Cost of domestic flights will be charged on actuals at time of booking
              </li>
              <li className="list-decimal ml-4">
                Local Tours, Private Vehicle bookings that are not included in the
                itinerary
              </li>
              <li className="list-decimal ml-4">
                Individual requests for performing specific rituals
              </li>
              <li className="list-decimal ml-4">
                Any last-minute train/flight cancellations, occurrences of this nature
              </li>
              <li className="list-decimal ml-4">
                Any personal loss of money, equipment etc of any kind
              </li>
              <li className="list-decimal ml-4">
                Any service, anything not specified in the booking itinerary is not
                included. Sukrit wellness tours and Kumbh Sukrit Camp is not responsible
                for any services not agreed upon at the time of booking
              </li>
            </ol>
          </Trans>
        ),
      },
    ],
    [t],
  );

  const ayodhya = useMemo(
    () => [
      {
        title: t('home:packages.ayodhya.day-1.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-1.itinerary">
            <ul>
              <li className="list-disc ml-4">Arrive in Delhi/Mumbai/Calcutta</li>
              <li className="list-disc ml-4">
                Transfer and take next flight to Varanasi
              </li>
              <li className="list-disc ml-4">
                Meet and greet at Varanasi airport. Check-in to hotel in Varanasi
              </li>
              <li className="list-disc ml-4">
                In the evening Boat ride in the Ganges seeing the iconic riverfront - a
                UNESCO world heritage site - Guided
              </li>
              <li className="list-disc ml-4">
                Participate in the prayers offered on the banks of Ganges - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-2.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-2.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Guided tour of ancient Budha Stupa - a UNESCO world heritage
              </li>
              <li className="list-disc ml-4">
                Guided tour of Swarved Mahāmandir Dham - World&rsquo;s largest meditation
                center
              </li>
              <li className="list-disc ml-4">
                Guided visit to local market - local shopping, souvenir
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-3.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-3.itinerary">
            <ul>
              <li className="list-disc ml-4">
                After breakfast check-out and travel to Kumbh Mela, Prayagraj
              </li>
              <li className="list-disc ml-4">
                Participate in the festivities of Kumbh Mela - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-4.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-4.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Take a dip in the holy waters of the Ganges - Guided
              </li>
              <li className="list-disc ml-4">Guided tour of Kumbh Mela in a group</li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-5.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-5.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Perform the sacred prayers; auspicious fire ceremony &lsquo;Yajna&rsquo;-
                Guided
              </li>
              <li className="list-disc ml-4">
                Participate in yoga & meditation, activities at the Kumbh camp - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-6.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-6.itinerary">
            <ul>
              <li className="list-disc ml-4">
                Guided tour of Maharshi Sadafal Deo Ashram
              </li>
              <li className="list-disc ml-4">
                Feed the holy cows in the Gau-shala - Guided
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-7.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-7.itinerary">
            <ul>
              <li className="list-disc ml-4">Boat ride in the Ganges - Guided</li>
              <li className="list-disc ml-4">
                Meeting the Saint, experiences of the real essence of Kumbh
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-8.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-8.itinerary">
            <ul>
              <li className="list-disc ml-4">
                After breakfast onboard flight/train from Prayagraj to Delhi
              </li>
              <li className="list-disc ml-4">
                Evening in the local market in Central Delhi - Guided
              </li>
              <li className="list-disc ml-4">
                Dinner and refresh in a hotel near Airport
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.day-9.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.day-9.itinerary">
            <ul>
              <li className="list-disc ml-4">Check-out after morning breakfast</li>
              <li className="list-disc ml-4">
                Onboard flight to next destination. Trip ends here
              </li>
            </ul>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.inclusions.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.inclusions.itinerary">
            <ol>
              <li className="list-decimal ml-4">
                Accommodation in Hotel in Varanasi on twin share{' '}
              </li>
              <li className="list-decimal ml-4">
                Stay at Kumbh Sukrit Camp in Kumbh Mela, Prayagraj on twin share
              </li>
              <li className="list-decimal ml-4">
                All meals (breakfast, lunch, dinner), drinking water is included as per
                the published itinerary{' '}
              </li>
              <li className="list-decimal ml-4">
                Local knowledgeable guide in English, Hindi speaking during all events
              </li>
              <li className="list-decimal ml-4">
                Private vehicle (based on size of group) for sightseeing and travel as per
                itinerary
              </li>
              <li className="list-decimal ml-4">
                Entrance fees, and all other costs as per itinerary
              </li>
              <li className="list-decimal ml-4">
                First aid, Doctor on call will be available at Kumbh Mela camp
              </li>
            </ol>
          </Trans>
        ),
      },
      {
        title: t('home:packages.ayodhya.exclusions.title'),
        content: (
          <Trans i18nKey="home:packages.ayodhya.exclusions.itinerary">
            <ol>
              <li className="list-decimal ml-4">International Flights</li>
              <li className="list-decimal ml-4">Visa and/or Passport services</li>
              <li className="list-decimal ml-4">
                Cost of domestic flights will be charged on actuals at time of booking
              </li>
              <li className="list-decimal ml-4">
                Local Tours, Private Vehicle bookings that are not included in the
                itinerary
              </li>
              <li className="list-decimal ml-4">
                Individual requests for performing specific rituals
              </li>
              <li className="list-decimal ml-4">
                Any last-minute train/flight cancellations, occurrences of this nature
              </li>
              <li className="list-decimal ml-4">
                Any personal loss of money, equipment etc of any kind
              </li>
              <li className="list-decimal ml-4">
                Any service, anything not specified in the booking itinerary is not
                included. Sukrit wellness tours and Kumbh Sukrit Camp is not responsible
                for any services not agreed upon at the time of booking
              </li>
            </ol>
          </Trans>
        ),
      },
    ],
    [t],
  );

  return { kumbh, varanasi, ayodhya } as const;
};

export const AccommodationAndPackagesSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [packageTitle, setPackageTitle] = useState('');
  const [packageId, setPackageId] = useState<PackageId>(PackageId.MAHAKUMBH);

  const onClose = useCallback(() => setIsOpen(false), []);
  const onClick = useCallback((packageTitle: string, packageId: PackageId) => {
    setIsOpen(true);
    setPackageTitle(packageTitle);
    setPackageId(packageId);
  }, []);

  const { t } = useTranslation(['home', 'common']);

  const significantDates = useMemo(
    () => [
      {
        date: t('home:dates.paush.date'),
        endDate: t('home:dates.paush.endDate'),
        title: t('home:dates.paush.title'),
        description: t('home:dates.paush.description'),
      },
      {
        date: t('home:dates.makar.date'),
        endDate: t('home:dates.makar.endDate'),
        title: t('home:dates.makar.title'),
        description: t('home:dates.makar.description'),
      },
      {
        date: t('home:dates.mauni.date'),
        endDate: t('home:dates.mauni.endDate'),
        title: t('home:dates.mauni.title'),
        description: t('home:dates.mauni.description'),
      },
      {
        date: t('home:dates.basant.date'),
        endDate: t('home:dates.basant.endDate'),
        title: t('home:dates.basant.title'),
        description: t('home:dates.basant.description'),
      },
      {
        date: t('home:dates.achal.date'),
        endDate: t('home:dates.achal.endDate'),
        title: t('home:dates.achal.title'),
        description: t('home:dates.achal.description'),
      },
      {
        date: t('home:dates.maghi.date'),
        endDate: t('home:dates.maghi.endDate'),
        title: t('home:dates.maghi.title'),
        description: t('home:dates.maghi.description'),
      },
      {
        date: t('home:dates.maha.date'),
        startDate: t('home:dates.maha.startDate'),
        title: t('home:dates.maha.title'),
        description: t('home:dates.maha.description'),
      },
    ],
    [t],
  );

  const packages = usePackageCards(t);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const onCloseGallery = useCallback(() => {
    setIsGalleryOpen(false);
  }, []);

  return (
    <>
      <PackageModal
        isOpen={isOpen}
        packageId={packageId}
        packageTitle={packageTitle}
        onClose={onClose}
      />
      <GalleryModal
        isOpen={isGalleryOpen}
        onClose={onCloseGallery}
        images={[
          images['mudhouse-1'],
          images['mudhouse-2'],
          images['mudhouse-3'],
          images['mudhouse-4'],
          images['mudhouse-5'],
          images['mudhouse-6'],
          images['mudhouse-7'],
        ]}
      />
      <section
        id="choose-your-haven"
        className="mt-16 md:mt-28 flex flex-col w-full px-8 sm:px-12 md:px-24 lg:px-40"
      >
        <h2 className="text-center md:text-left text-2xl md:text-3xl font-bold font-display">
          {t('home:accommodation.title')}
        </h2>
        <p className="mt-1 sm:mb-0 max-w-[640px] m-auto md:mx-[unset] text-center md:text-left font-serif">
          {t('home:accommodation.offerings')}
        </p>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="relative h-96 rounded-md w-full">
            <img
              src={images['mudhouse']}
              className="object-cover w-full h-full rounded-md"
              alt="The mudhouse option offered by Kumbh Sukrit"
            />
            <div className="h-60 bottom-0 left-0 absolute w-full bg-gradient-to-b from-black/0 to-black/60 rounded-b-md" />
            <div className="w-full absolute left-1/2 -translate-x-1/2 text-center md:text-left md:translate-x-0 md:left-8 bottom-5 md:bottom-8">
              <p className="font-display text-white text-2xl">
                {t('home:accommodation.mudhouse.title')}
              </p>
              <p className="text-white text-sm mt-1 w-full px-3 md:px-0">
                {t('home:accommodation.mudhouse.description')}
              </p>
              <div className="flex flex-row items-center justify-center md:justify-normal gap-2">
                <button
                  onClick={() =>
                    onOpenInquiry("I'm interested in your mudhouse accommodation!")
                  }
                  className="text-black uppercase font-bold text-xs tracking-widest bg-green-100 rounded-sm px-4 py-2 mt-4"
                >
                  {t('common:book-now')}
                </button>
                <button
                  onClick={() => setIsGalleryOpen(true)}
                  className="text-black uppercase font-bold text-xs tracking-widest bg-white rounded-sm px-4 py-2 mt-4"
                >
                  {t('common:see-more')}
                </button>
              </div>
            </div>
          </div>
          <div className="relative h-96 rounded-md w-full">
            <img
              src={images['mudhouse']}
              className="object-cover w-full h-full blur-[1px] rounded-md bg-green-200"
              alt="The thatch hut option offered by Kumbh Sukrit"
            />
            <div className="h-full bottom-0 left-0 absolute w-full bg-gradient-to-b from-black/0 to-black/100 rounded-b-md" />
            <div className="w-full absolute left-1/2 -translate-x-1/2 text-center md:text-left md:translate-x-0 md:left-8 bottom-5 md:bottom-8">
              <p className="font-display text-white text-2xl">
                {t('home:accommodation.premium-mudhouse.title')}
              </p>
              <p className="text-white text-sm mt-1 w-full px-3 md:px-0">
                {t('home:accommodation.premium-mudhouse.description')}
              </p>
              <button
                onClick={() =>
                  onOpenInquiry("I'm interested in your premium mudhouse accommodation!")
                }
                className="text-black uppercase font-bold text-xs tracking-widest bg-green-100 rounded-sm px-4 py-2 mt-4"
              >
                {t('common:book-now')}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full pt-10 pb-4 px-8 bg-green-200 mt-12">
          <div className="flex flex-col-reverse md:flex-row items-center justify-between w-full">
            <hgroup>
              <h2 className="text-center md:text-left font-display text-2xl">
                {t('home:camp67.interested')}
              </h2>
              <Trans i18nKey="home:camp67.explore">
                <p className="mt-1.5 text-center md:text-left">
                  Explore our sister offering,{' '}
                  <a
                    href="https://camp67.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    camp67
                  </a>{' '}
                  - located just aside Sanskriti Vann.
                </p>
              </Trans>
            </hgroup>
            <img src={images['c67Logo']} alt="" className="mb-4 md:mb-0 h-14 w-14" />
          </div>
          <div className="mt-4 flex flex-col md:grid-cols-3 md:grid w-full gap-3">
            <img className="w-full object-cover" alt="" src={images['c67Twin']} />
            <img className="w-full object-cover" alt="" src={images['c67FamilyPlus']} />
            <img className="w-full object-cover" alt="" src={images['c67DormSingles']} />
          </div>
          <a
            href="https://camp67.com"
            className="text-black self-end uppercase text-xs font-bold tracking-widest bg-green-100 rounded-sm px-2 py-2 mt-3"
          >
            {t('common:learn-more')}
          </a>
        </div>
        <span className="text-center md:text-left mt-8 font-serif tracking-wider uppercase">
          {t('home:accommodation.key-dates')}
        </span>
        <ul className="list-none grid grid-cols-1 xs:grid-cols-3 md:grid-cols-4 gap-6 items-start justify-center m-auto md:mx-[unset] mt-4">
          {significantDates.map(({ date, title, description }, index) => {
            return (
              <li
                key={index}
                className="px-5 relative py-4 rounded-md border border-green-300 shadow-md bg-white flex flex-col h-full gap-1"
              >
                <span className="uppercase text-gray-500 font-serif tracking-widest whitespace-nowrap text-sm">
                  {date}
                </span>
                <span className="font-serif font-bold text-sm md:text-base">{title}</span>
                <p className="text-sm">{description}</p>
                <button
                  onClick={() =>
                    onOpenInquiry(
                      `I'd like to come to Kumbh Sukrit on ${date} for ${title}!`,
                    )
                  }
                  className="self-start text-black uppercase font-bold text-[10px] tracking-widest bg-green-100 rounded-sm px-2 py-1 mt-2"
                >
                  {t('common:book-now')}
                </button>
              </li>
            );
          })}
        </ul>
        <h2 className="text-2xl md:text-3xl mt-16 md:mt-28 font-bold font-display text-center md:text-left">
          {t('home:accommodation.choose-journey')}
        </h2>
        <p className="mt-1 sm:mb-0 max-w-[640px] text-center md:text-left font-serif">
          {t('home:accommodation.interested')}
        </p>
        <ul className="mt-6 flex flex-row items-center w-full flex-wrap gap-6">
          {packages.map((packageCard, index) => (
            <PackageCard key={index} {...packageCard} onClick={onClick} />
          ))}
        </ul>
      </section>
    </>
  );
};

const GalleryModal = ({
  isOpen,
  onClose,
  images,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  images: string[];
}) => {
  const [activeImage, setActiveImage] = useState(0);
  const onPrev = useCallback(() => {
    setActiveImage((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  }, [images.length]);

  const onNext = useCallback(() => {
    setActiveImage((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  }, [images.length]);
  useDisableBodyScroll(isOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <button
        className="flex flex-col top-8 right-8 items-center justify-center w-10 h-10 absolute bg-white rounded-full"
        onClick={onClose}
      >
        <Sprite className="w-4 h-4" width="67" height="67" name="menu-close" />
      </button>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[95%] md:w-[80%] max-h-[600px] h-[95%]">
        <button
          onClick={onPrev}
          className="absolute flex flex-col items-center justify-center left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full w-10 h-10"
        >
          <Sprite strokeWidth={3} name="prev" width="13" height="21" />
        </button>
        <img className="w-full h-full object-contain" alt="" src={images[activeImage]} />
        <button
          onClick={onNext}
          className="absolute flex flex-col items-center justify-center right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full w-10 h-10"
        >
          <Sprite strokeWidth={3} name="next" width="12" height="21" />
        </button>
      </div>
    </Modal>
  );
};

const PackageModal = ({
  isOpen,
  onClose,
  packageId,
  packageTitle,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  packageId: PackageId;
  packageTitle: string;
}) => {
  const { t } = useTranslation(['home', 'common']);
  const { kumbh, varanasi, ayodhya } = usePackages(t);

  const itineraryForPackage =
    packageId === PackageId.VARANASI
      ? varanasi
      : packageId === PackageId.AYODHYA
        ? ayodhya
        : kumbh;

  const descriptionForPackage =
    packageId === PackageId.VARANASI
      ? t('home:packages.varanasi.summary')
      : packageId === PackageId.AYODHYA
        ? t('home:packages.ayodhya.summary')
        : t('home:packages.kumbh.summary');

  useDisableBodyScroll(isOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full px-8 sm:px-12 md:px-24 lg:px-40 max-h-[720px] h-[95%]">
        <div className="w-full h-full relative bg-green-100 rounded-md py-12 px-8 flex flex-col">
          <button onClick={onClose} className="absolute top-4 right-4">
            <Sprite name="menu-close" width="67" height="67" className="w-4 h-4" />
          </button>
          <h2 className="font-display text-2xl md:text-3xl text-center">
            {packageTitle} {t('common:package')}
          </h2>
          <p className="text-center text-sm md:text-base mt-1">{descriptionForPackage}</p>
          <div className="mt-4 overflow-y-auto flex flex-col h-[95%]">
            {itineraryForPackage.map(({ title, content }, key) => (
              <Accordion className="mt-2" title={title} content={content} key={key} />
            ))}
          </div>
          <div className="flex flex-col sm:flex-row sm:self-end items-center whitespace-nowrap gap-2">
            <button
              onClick={() => {
                copyTextToClipboard(getUrlForPackageId(packageId));
              }}
              className="text-black uppercase w-full md:w-[unset] md:self-end font-bold text-xs tracking-widest bg-white rounded-md px-4 py-4 mt-8 sm:mt-4"
            >
              {t('common:share')}
            </button>
            <button
              onClick={() => {
                onClose();
                onOpenInquiry(`I'm interested in your ${packageTitle} package!`);
              }}
              className="text-black uppercase w-full md:w-[unset] md:self-end font-bold text-xs tracking-widest bg-green-200 rounded-md px-4 py-4 mt-1"
            >
              {t('common:book-now')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
